import React, { useContext, useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { Formik, Form } from 'formik';
import { object } from 'yup';
import SanitizedHTML from 'react-sanitized-html';

import { AuthContext } from '../../../contexts/AuthContext';
import { ErrorContext } from '../../../contexts/ErrorContext';
import { FormGroup } from '../../shared/form/FormGroup';
import { Page } from '../../../components/shared/Page';

import { Layout } from '../../../components/shared/Layout';
import { Seo } from '../../../components/shared/Seo';
import { Button } from '../../shared/Button';
import { FormInput } from '../../shared/form/FormInput';
import { FormGroupError } from '../../shared/form/FormGroupError';
import { ErrorMessage } from '../../shared/form/ErrorMessage';
import { pin } from '../../../validations';
import { useGTMEvent } from '../../../hooks/useGTMEvent';

import {
  InvalidSmsCodeError,
  SMSTokenExpired,
  SmsLimitExceededError,
} from '../../../utilities/fetchJson';

const ValidationSchema = object().shape({ pin });

export const SmsCode = () => {
  const { hasSMSToken, verify, resendAuthSMS } = useContext(AuthContext);
  const { setGlobalError } = useContext(ErrorContext);
  const pushGTMEvent = useGTMEvent({
    event: 'login:registration:confirmation',
    eventCategory: 'login',
    eventAction: 'click',
    eventLabel: 'login_registration_confirmation',
  });

  const [remainingAttempts, setRemainingAttempts] = useState(null);
  const [error] = useState(null);
  const [customError, setCustomError] = useState(null);

  const initialFormValues = {
    pin: '',
  };

  useEffect(() => {
    if (!hasSMSToken()) {
      navigate('/ucet/registrace');
    }
  }, [hasSMSToken]);

  const onSubmit = async (data, { setSubmitting }) => {
    try {
      pushGTMEvent();
      await verify(data);
    } catch (e) {
      if (e instanceof SMSTokenExpired) {
        return setCustomError(e);
      }
      if (e instanceof InvalidSmsCodeError) {
        setSubmitting(false);
        return setCustomError(e);
      }
      setGlobalError(e);
    }
  };

  const resendSMS = async () => {
    try {
      const { remainingAttempts: ra } = await resendAuthSMS();
      setRemainingAttempts(ra);
    } catch (e) {
      if (e instanceof SMSTokenExpired || e instanceof SmsLimitExceededError) {
        return setCustomError(e);
      }
      setGlobalError(e);
    }
  };

  const enableSMSResend =
    (remainingAttempts === null || remainingAttempts > 0) &&
    (!customError || !customError.isSmsLimitExceeded);

  return (
    <Layout showLoginButton={false}>
      <Seo title="Ověřovací SMS" />
      <Page
        title="Potvrďte, že jste to Vy"
        description="Na telefonní číslo jsme Vám poslali SMS s kódem. Ten napište do prázdného políčka."
      >
        <Formik
          initialValues={initialFormValues}
          validationSchema={ValidationSchema}
          onSubmit={onSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            isSubmitting,
            isValid,
            dirty,
            errors,
            touched,
          }) => (
            <Form className="flex flex-col sm:max-w-sm mx-auto">
              <FormGroup className="mt-0">
                <FormInput
                  type="text"
                  name="pin"
                  placeholder="SMS kód"
                  errors={errors}
                  touched={touched}
                  onChange={handleChange}
                  data-testid="sms-code-pin"
                />
                <FormGroupError name="pin" />
                {error && <ErrorMessage>{error}</ErrorMessage>}
              </FormGroup>

              <Button
                type="submit"
                onSubmit={handleSubmit}
                disabled={isSubmitting || !dirty || !isValid}
                border="normal"
                className="mt-6"
                data-testid="sms-code-submit"
              >
                Pokračovat
              </Button>

              {enableSMSResend && (
                <div className="mt-6 text-center text-sm">
                  <p>Nepříšla Vám SMS?</p>
                  <button
                    type="button"
                    onClick={resendSMS}
                    className="ml-1 underline tracking-normal text-sm"
                    data-testid="sms-code-resend"
                  >
                    Poslat znovu
                  </button>
                  {remainingAttempts > 0 && (
                    <p>(počet zbývajícich pokusů: {remainingAttempts})</p>
                  )}
                </div>
              )}

              {customError && (
                <div>
                  <ErrorMessage
                    className="text-center pr-8"
                    data-testid="sms-code-custom-error"
                  >
                    <SanitizedHTML html={customError.humanFriendlyMessage} />
                  </ErrorMessage>
                  {customError.isSmsTokenExpired && (
                    <p
                      className="px-8 text-sm text-center"
                      data-testid="sms-code-custom-error-addition"
                    >
                      <Link to="/ucet/registrace" className="underline">
                        Registrujte se
                      </Link>
                      <span className="ml-1">znovu, prosím.</span>
                    </p>
                  )}
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Page>
    </Layout>
  );
};
